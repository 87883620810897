<template>
	<!-- 船舶监理进度设置 -->
	<div class="mod-config">
		<div>
			<!-- <el-card style=" height: 690px;" body-style="height:680px;"> -->
			<el-card>
				<el-row>
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="syncHandle()">同步</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" 
					:disabled="dataList.length == 0" @click="batchUpdateShowSortHandle()">排序保存</el-button>
				</el-row>
				<el-row style="height: 100%; margin-bottom: 10px;margin-top:10px">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<div class="infolist-head-container">
								<div class="infolist-head majorname">专业</div>
								<div class="infolist-head showsort">序号</div>
								<div class="infolist-head itemname">项目</div>
								<div class="infolist-head titlename">名称</div>
								<div class="infolist-head nodename">节点名称</div>
								<div class="infolist-head operator">操作</div>
							</div>
							<el-row v-for="(progress, index) in dataList">
								<el-col :span="3" style="margin-top: 8px;">
									<div style="font-weight: bolder;color: #00428e;">{{ progress.majorName }}</div>
								</el-col>
								<el-col :span="21">
									<el-button v-preventReClick type="text" size="small" class="min_btn"
										@click="addOrUpdateHandle('', progress.majorNo)">添加项目</el-button>
								</el-col>
								<div class="infolist-container">
									<div class="infolist-container-left"></div>
									<div class="infolist-container-right">
										<div class="infolist-container-right-row"
											v-for="(info, index) in progress.infoList">
											<div class="infolist-content showsort">
												<el-input v-model="info.showSort" class="sp-input"></el-input>
											</div>
											<div class="infolist-content itemname">{{ info.itemName }}</div>
											<div class="infolist-content titlename">{{ info.titleName }}</div>
											<div class="infolist-content nodename">{{ info.nodeName }}</div>
											<div class="infolist-content operator">
												<el-button v-preventReClick type="text" size="small" class="min_btn"
													style="margin-left: 20px;margin-top: 8px;"
													@click="addOrUpdateHandle(info.progressNo, info.majorNo)">修改</el-button>
												<el-button v-preventReClick type="text" size="small" class="min_btn"
													style="margin-left: 20px;"
													@click="deleteHandle(info.progressNo)">删除</el-button>
												<el-button v-preventReClick type="text" size="small" class="min_btn"
													style="margin-left: 20px;"
													@click="createTemplateHandle(info)">下载模板</el-button>
											</div>
										</div>
									</div>
								</div>
							</el-row>
						</div>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>
<script>
import $common from "@/utils/common.js"
import addOrUpdate from "./progress-setting.vue"
export default {
	data() {
		return {
			partNo: '',
			showSearch: true,
			showAdd: true,
			showDelete: true,
			showEdit: true,
			disabledBatchDelete: true,
			pageSpan: 12,
			addOrUpdateVisible: false,
			excelUploadVisible: false,
			updateFlg: false,
			addFlg: false,
			form: {},
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 10,
			// 显示序号
			pageIndex2: 1,
			//总页数
			totalPage: 0,
			workNo: $common.getItem("workNo"),
			subjectNo: '',
			dataList: [],
			multipleSelection: [],
			recNos: [],
			list: []
		};
	},
	components: {
		addOrUpdate
	},
	mounted() {
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		if (this.showSearch) {
			this.getDataList();
		}
	},
	methods: {
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectprogress/list/" + $common.getItem("workNo"),
				method: 'get',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body;
				}
			})
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectprogress/delete/" + id,
					method: 'post'
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				})
			});
		},
		createTemplateHandle(item) {
			var majorName = item.majorName;
            var projectName = item.itemName;
			var fileName = majorName + "-" + projectName + "进度表.xlsx"
			this.$confirm(`确定对选中的数据进行生成模板吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectprogress/createTemplate/" + item.progressNo,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.global.downLoadFile(data.body, fileName);
							}
						})
					}
				});
			})
		},
		addOrUpdateHandle(progressNo, majorNo) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(progressNo, majorNo)
			})
		},
		syncHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectprogress/syncProjectProgressInfo/" + $common.getItem("workNo"),
				method: 'post',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.getDataList()
						}
					})
				}
			})
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.recNos = []
			this.updateFlg = false
			this.getDataList();
		},
		batchUpdateShowSortHandle() {
			if (this.dataList.length > 0) {
				let records = []
				for(var i = 0; i < this.dataList.length; i++) {
					for(var j = 0; j < this.dataList[i].infoList.length; j++) {
						let record = {
						recId: this.dataList[i].infoList[j].progressNo,
						showSort: this.dataList[i].infoList[j].showSort
						}
						records.push(record)
					}
				}
				this.$http({
					url: this.$store.state.httpUrl + `/business/projectprogress/batchUpdateShowSort`,
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			}
		},
	}
};
</script>
<style scoped>
.infolist-container {
	border: solid #dadada 1px;
	display: flex;
	flex-direction: row;
}

.infolist-container-left {
	width: 9.3%;
}

.infolist-container-right {
	width: 90%;
}

.infolist-container-right-row {
	display: flex;
	flex-direction: row;
}

.infolist-head-container {
	display: flex;
	flex-direction: row;
}

.infolist-head {
	background-color: #E1F1FF;
	color: #00428E !important;
	font-weight: bolder !important;
	height: 40px;
	border-left: solid #dadada 1px;
	border-top: solid #dadada 1px;
	border-bottom: solid #dadada 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.infolist-head:last-child {
	border-right: solid #dadada 1px;
}

.infolist-head.majorname {
	width: 10%;
}

.infolist-head.showsort {
	width: 6%;
}

.infolist-head.itemname {
	width: 20%;
}

.infolist-head.titlename {
	width: 15%;
}

.infolist-head.nodename {
	width: 30%;
}

.infolist-head.operator {
	width: 25%;
}

.infolist-container-right-row:last-child .infolist-content {
	border-bottom: 0px;
}

.infolist-content {
	padding: 10px;
	border: solid #dadada 1px;
	border-right: 0px;
	border-top: 0px;
}

.infolist-content.showsort {
	width: 6.7%;
}

.infolist-content.itemname {
	width: 22.6%;
}

.infolist-content.titlename {
	width: 16.8%;
}

.infolist-content.nodename {
	width: 33.9%;
}

.infolist-content.operator {
	width: 27%;
}
</style>
